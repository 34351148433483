import {
  Batches,
  BiotrackDetails,
  BiotrackItems,
  DataToConcile,
  Pagination
} from "@/interfaces/biotrackInventoryReconciliation";
import { i18n } from "@/plugins/i18n";
import { biotrackInventoryReconciliationService } from "@/services/biotrackInventoryReconciliation.service";
import { PageNavAction } from "@/types/types";
import { PageTitleComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./BiotrackInventoryReconciliation.template.vue";

const tableHeaders = [
  {
    text: "",
    value: "icon",
    sortable: false,
    width: 1
  },
  {
    text: i18n.t("traceability_reconciliation.traceability_id").toString(),
    value: "traceability_id",
    sortable: true
  },
  {
    text: i18n.t("traceability_reconciliation.state_reporting_type").toString(),
    value: "state_reporting_type",
    sortable: true
  },
  {
    text: i18n.t("traceability_reconciliation.quantity").toString(),
    value: "quantity",
    sortable: true
  },
  {
    text: i18n.t("traceability_reconciliation.status").toString(),
    value: "status",
    sortable: false
  }
];

@Component({
  mixins: [Template],
  components: {
    PageTitleComponent
  }
})
export default class BiotrackInventoryReconciliationComponent extends Vue {
  /**
   * Getter filtered headers
   */
  protected get filteredHeaders() {
    return tableHeaders.filter(f => this.cellsToHide.indexOf(f.value) === -1);
  }
  /**
   * Getter filtered items
   */
  public get filteredItems() {
    return tableHeaders
      .filter(
        f => this.cellsToHide.indexOf(f.value) === -1 && f.value !== "actions"
      )
      .map(h => h.value);
  }

  /**
   * Getter table data
   */
  public get tableData() {
    this.fillDataTable();
    return {
      items: this.items,
      headers: this.headers
    };
  }
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Prop({ default: false }) public hideAction: boolean = false;
  public expand: boolean = false;
  public detailsData!: any;
  public items!: BiotrackItems;
  public batchId!: string;
  public loading = false;
  public pagination: Pagination = {
    rowsPerPage: 10,
    totalItems: 0,
    page: 1,
    descending: false
  };

  @Prop({ default: () => [] }) protected cellsToHide!: string[];
  protected headers: any[] = [];

  public batchReconciliationRoute(dataToConcile: DataToConcile, key = "") {
    if (key === "Add Batch") {
      this.$router.push({
        name: "biotrack-add-batch-reconciliation",
        params: {
          data: JSON.stringify(dataToConcile),
          listData: JSON.stringify({
            data: this.$route.params.detailsData,
            batchId: this.batchId
          })
        }
      });
    } else {
      this.$router.push({
        name: "biotrack-batch-reconciliation",
        params: {
          data: JSON.stringify(dataToConcile),
          listData: JSON.stringify({
            data: this.$route.params.detailsData,
            batchId: this.batchId
          })
        }
      });
    }
  }
  // to update the table's data
  public async loader() {
    this.loading = true;
    const query: {
      sort?: string;
      current_page?: number;
      page?: number;
      per_page?: number;
    } = {
      current_page: this.pagination!.page!,
      page: this.pagination!.page!,
      per_page: this!.pagination!.rowsPerPage!
    };
    if (this.pagination.sortBy) {
      query.sort = `${this.pagination.descending ? "-" : ""}${
        this.pagination.sortBy
      }`;
    }

    const response = await biotrackInventoryReconciliationService.getBiotrackdetails(
      this.batchId,
      query
    );
    if (response) {
      this.detailsData = response.data;
      this.fillDataTable();
    }
    this.loading = false;
  }

  @Watch("pagination", { deep: true, immediate: true })
  public async handler() {
    if (this.batchId) {
      this.loader();
    }
  }
  // calculating total (available quantity as well as reserved quantity) for a zeroth index room
  public totalQuantity(param: BiotrackDetails) {
    let quant = 0;
    const total =
      param &&
      param.summary &&
      param.summary.forEach((id: any) => {
        if (
          id.inventory_location.id === param.summary[0].inventory_location.id &&
          id.batch_fraction_status_type !== "SOLD"
        ) {
          quant = +quant + +id.quantity_value;
        }
      });
    return quant;
  }

  public reservedQuantity(param: BiotrackDetails) {
    let quant = 0;
    const total =
      param &&
      param.summary &&
      param.summary.forEach((id: any) => {
        if (
          id.inventory_location.id === param.summary[0].inventory_location.id &&
          id.batch_fraction_status_type === "RESERVED"
        ) {
          quant = +id.quantity_value;
          return;
        }
      });
    return quant;
  }

  public async reconcileAllQuantity() {
    const resp = await biotrackInventoryReconciliationService.postReconcileAll();
    if (resp!.data.status === "success") {
      this.loader();
    }
  }

  protected async mounted() {
    this.loading = true;
    if (this.$route.params.isDataReload) {
      const response = await biotrackInventoryReconciliationService.getBiotrackdetails(
        this.$route.params.batchId
      );
      if (response) {
        this.detailsData = response.data;
        this.batchId = this.$route.params.batchId;
      }
    } else {
      this.detailsData = cloneDeep(this.$route.params.detailsData);
      this.batchId = cloneDeep(this.$route.params.id);
    }
    if (this.detailsData === undefined) {
      this.$router.push({ name: "products-view" });
    }
    this.pagination.totalItems = +this.detailsData.total;
    this.fillDataTable();
    this.setPageNav(null);
    this.loading = false;
  }

  /**
   * Fills data table. Parses batches to match table structure.
   */
  protected fillDataTable() {
    this.headers = this.filteredHeaders.map(h => {
      h.text = String(this.$t(h.text));
      return h;
    });
    this.items = this.detailsData.data.map((batch: Batches) => {
      const biotrackDetails = JSON.parse(String(batch.biotrack_details));
      const traceabilityDetails = JSON.parse(
        String(batch.traceability_details)
      );
      batch.biotrack_details = biotrackDetails;
      batch.traceability_details = traceabilityDetails;
      return {
        data: batch,
        icon: "",
        traceability_id: [
          batch.biotrack_traceability_id,
          this.reservedQuantity(biotrackDetails) > traceabilityDetails.quantity
            ? true
            : false
        ],
        state_reporting_type: batch,
        quantity: batch,
        status: !batch.biotrack_details
          ? "Add Batch"
          : batch.is_sync === 1
          ? "IN SYNC"
          : this.reservedQuantity(biotrackDetails) >
            traceabilityDetails.quantity
          ? null
          : "Reconcile"
      }; // condition to show button in status column
    });
  }
}
