import { AxiosResponse } from "axios";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class BiotrackInventoryReconciliationService extends HttpService {
  public async getBatchsDetails(): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `/traceability/biotrack/reconciliation/inventory`
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
    return response!.data;
  }

  public async getBatchSyncingStatus(id: number) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "GET",
        url: `/traceability/biotrack/reconciliation/inventory/${id}`
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
    return response!.data;
  }

  public async getBiotrackdetails(id: string, query = {}) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "GET",
        url: `/traceability/biotrack/reconciliation/inventory/${id}/details`,
        params: query
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
    return response.data;
  }

  public async reconcileBatch(batch: any) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `/inventory/operations/biotrack/reconciliation`,
        data: batch
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
    return response!.data;
  }

  public async postReconcileAll() {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `/inventory/operations/biotrack/reconcile_all`
      });
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
    return response;
  }
}

export const biotrackInventoryReconciliationService: BiotrackInventoryReconciliationService = new BiotrackInventoryReconciliationService();
